import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'tabs/tab1',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'rodeo',
    loadChildren: () => import('./pages/rodeo/rodeo.module').then( m => m.RodeoPageModule)
  },
  {
    path: 'villaiglesia',
    loadChildren: () => import('./pages/villaiglesia/villaiglesia.module').then( m => m.VillaiglesiaPageModule)
  },
  {
    path: 'lasflores',
    loadChildren: () => import('./pages/lasflores/lasflores.module').then( m => m.LasfloresPageModule)
  },
  {
    path: 'bellavista',
    loadChildren: () => import('./pages/bellavista/bellavista.module').then( m => m.BellavistaPageModule)
  },
  {
    path: 'tudcum',
    loadChildren: () => import('./pages/tudcum/tudcum.module').then( m => m.TudcumPageModule)
  },
  {
    path: 'angualasto',
    loadChildren: () => import('./pages/angualasto/angualasto.module').then( m => m.AngualastoPageModule)
  },
  {
    path: 'transportes',
    loadChildren: () => import('./pages/transportes/transportes.module').then( m => m.TransportesPageModule)
  },
  {
    path: 'datos-utiles',
    loadChildren: () => import('./pages/datos-utiles/datos-utiles.module').then( m => m.DatosUtilesPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },  {
    path: 'eventos',
    loadChildren: () => import('./pages/eventos/eventos.module').then( m => m.EventosPageModule)
  },
  {
    path: 'politica-privacidad',
    loadChildren: () => import('./pages/politica-privacidad/politica-privacidad.module').then( m => m.PoliticaPrivacidadPageModule)
  }



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
